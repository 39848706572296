import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import {
  FaArrowUp,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import './Footer.css';

import logo from '../../../assets/images/logos/mcgp-logo.png';

function Footer() {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <footer>
      <div className="footer-area">
        <div className="footer-scroll-wrap">
          <Button className="scroll-to-target" onClick={scrollToTop}>
            <FaArrowUp />
          </Button>
        </div>
        <Container>
          <div className="footer-top">
            <Row>
              <Col
                className="footer-widget"
                data-wow-delay=".2s"
                lg="4"
                sm="12"
              >
                <Link to="/" className="footer-logo">
                  <img src={logo} alt="logo" />
                </Link>
                <div className="footer-content">
                  <p>
                    Invest, build, and earn with Millionaire Circle Green
                    Protocol.
                  </p>
                  <ul className="footer-social">
                    <li>
                      <Link
                        to="https://www.linkedin.com/in/millionaire-circle-23320527b"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.facebook.com/profile.php?id=100093244977134"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/MillionaireMCGP?t=_HFpwFhxH35ASuLC_BnWpA&s=09"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://t.me/Millionaire_CircleGP"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTelegram />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="tiktok.com/@Millionaire_Circle"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTiktok />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://instagram.com/millionaire_circle1?igshid=ZGUzMzM3NWJiOQ=="
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com/@MillionaireCircle-MCGP"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube />
                      </Link>
                    </li>
                  </ul>
                  <div className="mail mt-3">
                    <Link to="#">millionairecirclegreenprotocol@gmail.com</Link>
                  </div>
                </div>
              </Col>
              <Col
                className="footer-widget"
                data-wow-delay=".4s"
                lg="4"
                sm="12"
              >
                <div className="fw-title">
                  <h4>Useful Links</h4>
                </div>
                <div className="footer-link">
                  <div className="link-item">
                    <Link to="/about">About us</Link>
                  </div>
                  <div className="link-item">
                    <Link to="/terms">Terms of Service</Link>
                  </div>
                </div>
              </Col>
              <Col
                className="footer-widget"
                data-wow-delay=".6s"
                lg="4"
                sm="12"
              >
                <div className="fw-title">
                  <h4>Community</h4>
                </div>
                <div className="footer-link">
                  <div className="link-item">
                    <Link to="#/partners">Partners</Link>
                  </div>
                  <div className="link-item">
                    <Link to="#">Blog</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer-bottom">
            <Row>
              <Col className="text-center" lg="12">
                <p>
                  © 2023
                  <span> Millionaire Circle Green Protocol</span>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
