import React, { createContext,  useState } from 'react';

const AppContext = createContext();

// eslint-disable-next-line react/prop-types
const AppProvider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  return (
    <AppContext.Provider
      value={{
        provider,
        setProvider,
        signer,
        setSigner,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
