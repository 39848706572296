import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import axiosService from '../../services/axiosService';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState(currentUser?.name || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [wallet, setWallet] = useState(currentUser?.wallet || '');

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/login');
    }
  }, [currentUser]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSave = async () => {
    try {
      // Make API call to update user profile
      const updatedProfile = {
        name,
        email,
        wallet,
      };

      await axiosService.patch(
        `/users?userId=${currentUser.id}`,
        updatedProfile
      );

      // Close the modal
      toggleModal();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className="user-profile-container">
      <h2>User Profile</h2>
      {currentUser ? (
        <div className="user-profile-details">
          <p>
            <strong>Name:</strong> {currentUser.name}
          </p>
          <p>
            <strong>Email:</strong> {currentUser.email}
          </p>
          <Button onClick={toggleModal}>Edit Profile</Button>
        </div>
      ) : (
        <p>Loading user data...</p>
      )}

      {/* Modal for editing profile */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Profile</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="wallet">Wallet Address</Label>
            <Input
              type="text"
              id="wallet"
              value={wallet}
              onChange={(e) => setWallet(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserProfile;
