import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import axiosService from '../../services/axiosService';
import { toast } from 'react-toastify';

const OrderDetail = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);

  const fetchOrder = async () => {
    try {
      const response = await axiosService.get(`/order/${orderId}`);
      const buyerResponse = await axiosService.get(
        `/users/${response.data.buyer}`
      );
      setOrder({ ...response.data, buyerName: buyerResponse.data.name });
    } catch (error) {
      console.error('Error fetching order:', error);
      toast.error('Error fetching order');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  const toggleConfirmationModal = () =>
    setConfirmationModal(!confirmationModal);
  const togglePreviewModal = () => setPreviewModal(!previewModal);

  const handleAcknowledgement = async () => {
    try {
      const body = { isPaymentReceived: true };
      await axiosService.post(`/order/${orderId}/acknowledge`, body);
      toast.success('Payment acknowledged successfully');
      fetchOrder();
      toggleConfirmationModal();
    } catch (error) {
      console.error('Error acknowledging payment:', error);
      toast.error('Error acknowledging payment');
    }
  };

  if (loading) {
    return (
      <div className="main-dashboard">
        <Container>
          <Spinner color="primary" />
          <p>Loading Order Details...</p>
        </Container>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="main-dashboard">
        <Container>
          <p>Order not found.</p>
        </Container>
      </div>
    );
  }

  return (
    <div className="main-dashboard">
      <Container>
        <h2>Order Details</h2>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h4>Order Information</h4>
                <p>
                  <strong>Order ID:</strong> {order.id}
                </p>
                <p>
                  <strong>Buyer:</strong> {order.buyerName}
                </p>
                <p>
                  <strong>Amount:</strong> {order.amount}
                </p>
                <p>
                  <strong>Payment Status:</strong> {order.paymentStatus}
                </p>
                <p>
                  <strong>Paid At:</strong>{' '}
                  {order.isPaid
                    ? new Date(order.paidAt).toLocaleDateString()
                    : 'N/A'}
                </p>
                <p>
                  <strong>Delivery Status:</strong>{' '}
                  {order.isDelivered ? 'Delivered' : 'Not Delivered'}
                </p>
                <p>
                  <strong>Delivered At:</strong>{' '}
                  {order.isDelivered
                    ? new Date(order.isDelivered).toLocaleDateString()
                    : 'N/A'}
                </p>
                <p>
                  <strong>Created At:</strong>{' '}
                  {new Date(order.createdAt).toLocaleDateString()}
                </p>
              </Col>
              <Col>
                <h4>Delivery Address</h4>
                <p>{order.deliveryAddress.fullName}</p>
                <p>{order.deliveryAddress.phoneNumber}</p>
                <p>
                  {order.deliveryAddress.address}, {order.deliveryAddress.city},{' '}
                  {order.deliveryAddress.state}, {order.deliveryAddress.country}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <h4>Products</h4>
                {order.product.map((product) => (
                  <Card key={product._id} className="mb-3">
                    <Row noGutters>
                      <Col md="2">
                        <CardBody className="d-flex align-items-center">
                          <img
                            src={
                              product.advert.featuredImage ||
                              product.advert.images[0]
                            }
                            alt={product.name}
                            style={{ width: '100%' }}
                          />
                        </CardBody>
                      </Col>
                      <Col md="10">
                        <CardBody>
                          <div className="d-flex justify-content-between">
                            <div>{product.advert.name}</div>
                            <div>{product.quantity}</div>
                            <div>{product.price}</div>
                            <div>{product.advert.companyName}</div>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Col>
              <Col md={6} sm={12}>
                <h4>Payment Proof</h4>
                {order.paymentProof ? (
                  <img
                    src={order.paymentProof}
                    alt="Payment Proof"
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                    onClick={togglePreviewModal}
                  />
                ) : (
                  <p>No payment proof provided.</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-between w-lg-50 mt-4">
                  <Button onClick={toggleConfirmationModal} color="success">
                    Acknowledge Payment
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>

      <Modal isOpen={confirmationModal} toggle={toggleConfirmationModal}>
        <ModalHeader toggle={toggleConfirmationModal}>
          Confirm Payment
        </ModalHeader>
        <ModalBody>
          Are you sure you want to acknowledge this payment?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleConfirmationModal}>
            Cancel
          </Button>
          <Button color="success" onClick={handleAcknowledgement}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={previewModal} toggle={togglePreviewModal}>
        <ModalHeader toggle={togglePreviewModal}>Payment Proof</ModalHeader>
        <ModalBody>
          <img
            src={order.paymentProof}
            alt="Payment Proof"
            style={{ width: '100%' }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePreviewModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OrderDetail;
