import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './partners.css';

import latoken from '../../assets/images/latoken.png'
import dextools from '../../assets/images/dextools.png'
import tsa from '../../assets/images/tsa.png'

// Example partner data
const partners = [
  {
    name: 'Partner 1',
    logo: latoken,
    link: 'https://latoken.com/',
  },
  {
    name: 'Partner 2',
    logo: dextools,
    link: 'https://www.dextools.io/app/en/fantom/pair-explorer/0x7a839c5324246a4d45419380ebc58f3fd136b823?t=1708627226281',
  },
  {
    name: 'Partner 3',
    logo: tsa,
    link: '/download.apk',
  },
  // Add more partners as needed
];

export default function Partners() {
  return (
    <section id="partners" className="section-wrapper">
      <Container>
        <div className="section-head text-center">
          <h2 className="section-title">Our Partners</h2>
        </div>
        <Row className="align-items-center justify-content-center">
          {partners.map((partner, index) => (
            <Col key={index} sm={6} md={4} lg={3} className="mb-4">
              <a href={partner.link} target="_blank" rel="noopener noreferrer">
                <div className="partner-logo-wrapper">
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="partner-logo"
                  />
                </div>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
