import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import { animateScroll as scroll, scrollSpy } from 'react-scroll';
import { Container, Nav, Navbar } from 'reactstrap';
import { FaBars, FaTimes } from 'react-icons/fa';
import MobileMenu from '../../navs/MobileNav';
import logo from '../../../assets/images/logos/mcgp-logo.png';

function MainNavigation() {
  const [isLoggedIn] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTabletOrMobile, setIsTabletOrMobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsDesktop(width > 1024);
      setIsTabletOrMobile(width <= 1024);
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  useEffect(() => {
    scrollSpy.update();
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    scroll.scrollTo(sectionId, {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset: -80,
    });
    toggleMobileMenu();
  };

  const isNavLinkActive = (path) => {
    return activeTab === path ? 'active' : '';
  };

  return (
    <header>
      {isMobileMenuOpen && (
        <div className="overlay" onClick={toggleMobileMenu} />
      )}

      <Navbar
        className={`navbar ${isScrolled ? 'scrolled' : ''}`}
        expand="md"
        fixed="top"
      >
        <Container className="d-flex justify-content-between align-items-center">
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="logo" />
          </Link>

          <div className="navbar-right d-flex justify-content-between align-items-center">
            {isDesktop ? (
              <Nav className="ml-auto" navbar>
                <Link
                  className={`nav-link mr-1 ${isNavLinkActive('/')}`}
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className={`nav-link mr-1 ${isNavLinkActive('/about')}`}
                  to="/about"
                >
                  About
                </Link>
                <Link
                  className={`nav-link mr-1 ${isNavLinkActive('/roadmap')}`}
                  to="/"
                  onClick={() => scrollToSection('roadmap')}
                >
                  Roadmap
                </Link>
                <div className="user">
                  <div className="menu-btn">
                    <a
                      className="nav-link btn login-btn"
                      href="/download.apk"
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </Nav>
            ) : (
              <div className="navicon" onClick={toggleMobileMenu}>
                {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
              </div>
            )}
          </div>
        </Container>
      </Navbar>

      {isTabletOrMobile && (
        <MobileMenu
          isOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          isLoggedIn={isLoggedIn}
        />
      )}
    </header>
  );
}

export default MainNavigation;
