import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Spinner } from 'reactstrap';
import axiosService from '../../services/axiosService';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosService.get('/category');
        setCategories(response.data.results);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error(`Error fetching categories: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleDeleteCategory = async (categoryId) => {
    const updatedCategories = categories.filter(
      (category) => category.id !== categoryId
    );
    setCategories(updatedCategories);

    try {
      await axiosService.delete(`/category/${categoryId}`);
      toast.success('Category deleted successfully');
    } catch (error) {
      console.error('Error deleting category:', error);
      setCategories(categories); 
      toast.error(`Error deleting category: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <div
        className="main-dashboard"
        style={{ textAlign: 'center', marginTop: '20%' }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div className="main-dashboard">
      <Container>
        <h2>Categories</h2>
        {categories.length === 0 ? (
          <p>No categories available.</p>
        ) : (
          <Table responsive>
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category.id}>
                  <td>{category.title}</td>
                  <td>{category.description}</td>
                  <td>{category.type}</td>
                  <td>
                    <Button
                      tag={Link}
                      to={`/admin/categories/${category.id}`}
                      color="info"
                    >
                      Edit
                    </Button>{' '}
                    <Button
                      onClick={() => handleDeleteCategory(category.id)}
                      color="danger"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Button tag={Link} to="/admin/categories/new" color="success">
          Add New Category
        </Button>
      </Container>
    </div>
  );
};

export default CategoryList;
