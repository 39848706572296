import React, { useState } from 'react';
import { Container } from 'reactstrap';

import './Hero.css';

import { Link } from 'react-router-dom';

import pdf from '../../assets/whitepaper.pdf';
import BuyNowModal from '../modal/BuyNowModal';

export default function Hero() {
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  return (
    <>
      <div className="hero">
        <div className=" hero-content d-flex justify-content-center align-items-center">
          <Container>
            <div className="content-title">
              <h1 className="main-title text-center">
                Welcome to Millionaire Circle <br /> Green protocol!
              </h1>
              <p className="text-center sub-title">
                Transforming Real Estate, Agricultural Industries and other Real
                World Assets through blockchain.
              </p>
              <div className="get-sterted">
                <Link
                  className="btn btn-outline-warning hero-btn btn-gradient btn-shadow"
                  onClick={toggleModal}
                >
                  Buy now
                </Link>

                <Link
                  className="btn btn-outline-success paper-btn"
                  to={pdf}
                  target="_blank"
                >
                  White Paper
                </Link>
              </div>
            </div>
            <BuyNowModal isOpen={modal} toggle={toggleModal} />
          </Container>
        </div>
      </div>
    </>
  );
}
