import React from 'react';
import { Container } from 'reactstrap';

import './GettingStarted.css';

export default function GettingStarted() {
  return (
    <>
      <section
        id="getting-started"
        className="section-wrapper bg-primary-light"
      >
        <Container>
          <div className="content-inner-1">
            <div className="section-head text-center">
              <h2 className="section-title">
                Empowering Growth and Wealth Through{' '}
                <span className="mcgp">$MCGP</span> TOKENIZATION ECOSYSTEM{' '}
              </h2>
              <p className="text-left" style={{ textAlign: 'left' }}>
                In the dynamic world of finance, the Tokenization ecosystem
                serves as a gateway to a future where barriers to entry are
                lowered, and opportunities for growth are limitless. By
                embracing Tokenization, investors and the masses alike can
                benefit from a more inclusive and efficient financial system.
                Tokenization democratizes access to assets that were once out of
                reach, allowing individuals to invest in fractions of valuable
                assets and diversify their portfolios with ease. This
                transformative technology not only unlocks new sources of
                liquidity but also enhances transparency and security in the
                financial market. Embrace Tokenization, embrace a future where
                financial empowerment knows no bounds. <br />
                <div style={{ marginTop: '20px' }}>
                  <span className="mcgp">$MCGP</span>, The future is now!
                </div>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
