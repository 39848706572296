import React from 'react';
import Hero from '../components/landing/Hero';
import GettingStarted from '../components/landing/GettingStarted';
import AboutSection from '../components/landing/About';
import Roadmap from '../components/landing/Roadmap';
import AssetTokenization from '../components/landing/AssetTokenization';
import TokenDistribution from '../components/landing/TokenDistribution';
import Partners from '../components/landing/Partners';
import Disclaimer from '../components/landing/Disclaimer';

export default function Home() {
  return (
    <>
      <Hero />
      <GettingStarted />
      <AboutSection />
      <Partners />
      <Roadmap />
      <TokenDistribution />
      <AssetTokenization />
      <Disclaimer />
    </>
  );
}
