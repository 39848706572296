import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import axiosService from '../../services/axiosService';

const AddCategory = () => {
  const preset = 'tsa_preset';
  const cloudinary_name = 'dgd0vop7e';
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [hasParent, setHasParent] = useState(false);
  const [parentCategories, setParentCategories] = useState([]);

  useEffect(() => {
    // Fetch parent categories if hasParent is true
    if (hasParent) {
      fetchParentCategories();
    }
  }, [hasParent]);

  const fetchParentCategories = async () => {
    try {
      const response = await axiosService.get(`/category?isParent=true`);
      setParentCategories(response.data.results);
    } catch (error) {
      console.error('Error fetching parent categories:', error);
      toast.error('Error fetching parent categories');
    }
  };

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', preset);

    setUploading(true);

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudinary_name}/image/upload`,
        formData
      );
      setImageUrl(response.data.secure_url);
      toast.success('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
    } finally {
      setUploading(false);
    }
  };

  const onSubmit = async (data) => {
    if (!imageUrl) {
      toast.error('Please upload an image');
      return;
    }
    data.image = imageUrl;
    data.isFeatured = !!data.isFeatured; 
    data.isParent = !!data.isParent; 

    try {
      await axios.post('/category', data);
      toast.success('Category added successfully');
      reset();
      navigate('/admin/categories');
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Error adding category');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const handleIsParentChange = (e) => {
    setHasParent(e.target.checked);
    if (e.target.checked) {
      fetchParentCategories();
    }
  };

  return (
    <div className="main-dashboard">
      <Container>
        <h2>Add New Category</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              id="title"
              name="title"
              {...register('title', { required: 'Title is required' })}
              invalid={!!errors.title}
            />
            {errors.title && (
              <FormFeedback>{errors.title.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              {...register('description', {
                required: 'Description is required',
              })}
              invalid={!!errors.description}
            />
            {errors.description && (
              <FormFeedback>{errors.description.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="type">Type</Label>
            <Input
              id="type"
              name="type"
              type="select"
              {...register('type', { required: 'Type is required' })}
              invalid={!!errors.type}
            >
              <option value="">Select Type</option>
              <option value="product">Product</option>
              <option value="service">Service</option>
            </Input>
            {errors.type && <FormFeedback>{errors.type.message}</FormFeedback>}
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" {...register('isFeatured')} /> Featured
              Category
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" {...register('isParent')} /> Parent Category
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                {...register('parentCategory')}
                onChange={handleIsParentChange}
              />{' '}
              Select Parent Category
            </Label>
          </FormGroup>
          {hasParent && (
            <FormGroup>
              <Label for="parentCategory">Parent Category</Label>
              <Input
                type="select"
                name="parentCategory"
                id="parentCategory"
                {...register('parentCategory', {
                  required: 'Parent Category is required',
                })}
              >
                <option value="">Select Parent Category</option>
                {parentCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="image">Image</Label>
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? 'active' : ''}`}
              style={{
                border: '1px dashed #ddd',
                padding: '20px',
                textAlign: 'center',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>Drag & drop an image here, or click to select one</p>
              )}
            </div>
            {uploading && <p>Uploading...</p>}
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Category"
                style={{ marginTop: '20px', maxWidth: '100%' }}
              />
            )}
          </FormGroup>
          <Button color="primary" type="submit" disabled={uploading}>
            Add Category
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default AddCategory;
