import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import axiosService from '../../services/axiosService';
import { toast } from 'react-toastify';

const AdvertsDetails = () => {
  const { advertId } = useParams();
  const [advert, setAdvert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const response = await axiosService.get(`/adverts/${advertId}`);
        setAdvert(response.data);
      } catch (error) {
        console.error('Error fetching ad:', error);
        toast.error('Error fetching ad details');
      } finally {
        setLoading(false);
      }
    };

    fetchAd();
  }, [advertId]);

  const toggleModal = (image) => {
    setSelectedImage(image);
    setModalOpen(!modalOpen);
  };

  if (loading) {
    return (
      <div className="main-dashboard">
        <Container>
          <Spinner color="primary" />
          <p>Loading Ad Details...</p>
        </Container>
      </div>
    );
  }

  if (!advert) {
    return (
      <div className="main-dashboard">
        <Container>
          <p>Advert not found.</p>
        </Container>
      </div>
    );
  }

  return (
    <div className="main-dashboard">
      <Container>
        <Row>
          <Col md={6}>
            <Card>
              <CardImg
                top
                src={advert.images[0]}
                alt={advert.name}
                onClick={() => toggleModal(advert.images[0])}
                style={{ cursor: 'pointer' }}
              />
              <CardBody>
                <CardTitle tag="h5">{advert.name}</CardTitle>
                <CardText>{advert.companyName}</CardText>
              </CardBody>
            </Card>
            {/* Modal for Image Preview */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
              <ModalBody>
                <img
                  src={selectedImage}
                  alt={advert.name}
                  className="img-fluid"
                />
              </ModalBody>
            </Modal>
            {/* Preview of Remaining Images */}
            <Row className="mt-3">
              {advert.images.slice(1).map((image, index) => (
                <Col md={2} key={index} className="mb-2">
                  <CardImg
                    src={image}
                    alt={advert.name}
                    onClick={() => toggleModal(image)}
                    style={{ cursor: 'pointer' }}
                    className="img-thumbnail"
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Product Details</CardTitle>
                <ul>
                  <li>
                    <strong>Price:</strong> ₦{advert.price}
                  </li>
                  <li>
                    <strong>Location:</strong> {advert.location}
                  </li>
                  <li>
                    <strong>Phone Number:</strong> {advert.phoneNumber}
                  </li>
                  <li>
                    <strong>Stock:</strong> {advert.stock}
                  </li>
                  <li>
                    <strong>Status:</strong> {advert.status}
                  </li>
                  <li>
                    <strong>Is Available:</strong>{' '}
                    {advert.isAvailable ? 'Yes' : 'No'}
                  </li>
                  <li>
                    <strong>Created At:</strong>{' '}
                    {new Date(advert.createdAt).toLocaleDateString()}
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Description</CardTitle>
                <p>{advert.description}</p>
                <h3>Attributes:</h3>
                <ul>
                  {advert.attributes.map((attribute) => (
                    <li key={attribute._id}>
                      {attribute.name}: {attribute.values.join(', ')}
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle tag="h5">
                  Reviews{' '}
                  <span style={{ fontSize: '11px', color: 'gray' }}>
                    {advert.reviews.length}
                  </span>
                </CardTitle>
                {advert.reviews.map((review) => (
                  <div key={review._id}>
                    <p>
                      <strong>Rating:</strong> {review.rating}
                    </p>
                    <p>
                      <strong>Review:</strong> {review.reviewText}
                    </p>
                    <p>
                      <strong>Reviewed By:</strong> {review.reviewedBy}
                    </p>
                    <p>
                      <strong>Date:</strong>{' '}
                      {new Date(review.date).toLocaleDateString()}
                    </p>
                    <hr />
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AdvertsDetails.propTypes = {
  advert: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    location: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    stock: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    negotiable: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        reviewedBy: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
        reviewText: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default AdvertsDetails;
