import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
  Alert,
  Table,
} from 'reactstrap';
import axiosService from '../../services/axiosService';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

const UserDetail = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse = await axiosService.get(`/users/${userId}`);
        setUser(userResponse.data);

        const accountResponse = await axiosService.get(`/account/${userId}`);
        setAccount(accountResponse.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Error fetching user details');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  if (loading) {
    return (
      <div className="main-dashboard">
        <Container>
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="main-dashboard">
        <Container>
          <Alert color="danger">{error}</Alert>
        </Container>
      </div>
    );
  }
  console.log(account);
  return (
    <div className="main-dashboard">
      <Container>
        <h2>User Details</h2>
        {user && (
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h5">{user.name}</CardTitle>
              <CardText>Email: {user.email}</CardText>
              <CardText>Phone Number: {user.phoneNumber}</CardText>
              <CardText>Country: {user.country}</CardText>
              <CardText>
                KYC Status: {user.kycVerified ? 'Verified' : 'Not verified'}
              </CardText>
            </CardBody>
          </Card>
        )}

        <h3>Withdrawal Details</h3>
        {account.withdrawalDetails.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>Account Number</th>
                <th>Account Name</th>
                <th>Bank Name / Wallet Address</th>
                <th>Symbol / Network</th>
              </tr>
            </thead>
            <tbody>
              {account.withdrawalDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.type}</td>
                  <td>{detail.accountNumber}</td>
                  <td>{detail.accountName}</td>
                  <td>{detail.bankName || detail.walletAddress}</td>
                  <td>{detail.symbol || detail.network}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No withdrawal details available.</p>
        )}
      </Container>
    </div>
  );
};

UserDetail.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserDetail;
