import React from 'react';
import { Container } from 'reactstrap';
import './disclaimer.css';

export default function Disclaimer() {
  return (
    <section id="disclaimer" className="disclaimer-section">
      <Container>
        <p className="disclaimer-text">
          This website does not constitute an offer to purchase or solicitation
          to sell, nor is it a recommendation to buy or sell, any token or other
          product. Purchasing and selling tokens is inherently risky and holders
          must bear their risk of loss. Neither the authors of this website nor
          any participants in the MCGP project accept any liability for losses
          or taxes that holders, purchasers or sellers of MCGP may incur. The
          value of MCGP may decrease and may be highly volatile.
        </p>
        <p className="disclaimer-text">
          This website is not directed towards any person located in a
          jurisdiction where purchasing, selling or holding $MCGP is prohibited
          or regulated. Consult your local laws before transacting in any
          cryptocurrency.
        </p>
      </Container>
    </section>
  );
}
