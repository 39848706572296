import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'reactstrap';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTiktok,
  FaTwitter,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import './MobileNav.css';

function MobileMenu({ isOpen, toggleMobileMenu }) {
  return (
    <div className={`navbar-card ${isOpen ? 'open' : ''}`}>
      <Nav className="ml-auto" navbar>
        {/* Nav Links */}
        <Link className="nav-link" to="/" onClick={toggleMobileMenu}>
          Home
        </Link>
        <Link className="nav-link" to="/about" onClick={toggleMobileMenu}>
          About
        </Link>
        <Link className="nav-link" to="/#roadmap" onClick={toggleMobileMenu}>
          Roadmap
        </Link>

        {/* User Section */}
        <div className="user">
          <a
            className="nav-link"
            href="/download.apk"
            onClick={toggleMobileMenu}
          >
            TSA Connect
          </a>
        </div>

        {/* Social Icons */}
        <div className="navbar-bottom">
          <div className="social-icons">
            <Link
              to="https://www.linkedin.com/in/millionaire-circle-23320527b"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMobileMenu}
            >
              <FaLinkedin />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=100093244977134"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMobileMenu}
            >
              <FaFacebookF />
            </Link>
            <Link
              to="https://twitter.com/MillionaireMCGP?t=_HFpwFhxH35ASuLC_BnWpA&s=09"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMobileMenu}
            >
              <FaTwitter />
            </Link>
            <Link
              to="https://t.me/Millionaire_CircleGP"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMobileMenu}
            >
              <FaTelegram />
            </Link>
            <Link
              to="tiktok.com/@Millionaire_Circle"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMobileMenu}
            >
              <FaTiktok />
            </Link>
            <Link
              to="https://instagram.com/millionaire_circle1?igshid=ZGUzMzM3NWJiOQ=="
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMobileMenu}
            >
              <FaInstagram />
            </Link>
          </div>
        </div>
      </Nav>
    </div>
  );
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default MobileMenu;
