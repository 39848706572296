import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import token from '../../assets/images/distribution.png';

const TokenDistribution = () => {
  return (
    <Row>
      <Container>
        <Col>
          <div className="section-head mt-3">
            <h2 className="title">Token Distribution</h2>
          </div>
          <div className="d-flex justify-content-center">
            <img src={token} alt="Token distribution chart" />
          </div>
          <div className="token-dist" style={{ marginLeft: '20px' }}>
            <p>
              <strong>Total Suply: </strong> 200,000,000{' '}
              <span style={{ color: 'gold' }}>MCGP</span>
            </p>
            <p>
              <strong>Contract address:</strong>{' '}
              0x7e05B4bcD55dB05eE77684f41E1Af9e29F48ddB1
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

export default TokenDistribution;
