import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Spinner,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import axiosService from '../../services/axiosService';

const KYCRequests = () => {
  const [kycRequests, setKYCRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState('');

  const fetchKYCRequests = async () => {
    try {
      const response = await axiosService.get('/kyc/request');
      setKYCRequests(response.data.results);
    } catch (error) {
      console.error('Error fetching KYC requests:', error);
      setError('Error fetching KYC requests');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKYCRequests();
  }, []);

  const handleApprove = async () => {
    try {
      await axiosService.post(`/kyc/${selectedRequest?.id}/approve`);
      setKYCRequests(
        kycRequests.filter((req) => req.id !== selectedRequest?.id)
      );
      fetchKYCRequests();
      toggleModal();
    } catch (error) {
      console.error('Error approving KYC request:', error);
      setError('Error approving KYC request');
    }
  };

  const handleReject = async () => {
    try {
      await axiosService.put(`/kyc/${selectedRequest?.id}/reject`, {
        rejectionReasons,
      });
      setKYCRequests(
        kycRequests.filter((req) => req.id !== selectedRequest?.id)
      );
      fetchKYCRequests();
      toggleModal();
      setRejectionReasons(''); // Clear rejection reasons after submission
    } catch (error) {
      console.error('Error rejecting KYC request:', error);
      setError('Error rejecting KYC request');
    }
  };

  const toggleModal = (request = null) => {
    setSelectedRequest(request);
    setModalOpen(!modalOpen);
  };

  const handleReasonsChange = (event) => {
    setRejectionReasons(event.target.value);
  };

  if (loading) {
    return (
      <div className="main-dashboard">
        <Container>
          <Spinner color="primary" />
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="main-dashboard">
        <Container>
          <Alert color="danger">{error}</Alert>
        </Container>
      </div>
    );
  }

  return (
    <div className="main-dashboard">
      <Container>
        <h2>KYC Requests</h2>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>ID Type</th>
              <th>DOB</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {kycRequests.map((request) => (
              <tr key={request._id}>
                <td>{`${request.firstName} ${
                  request.middleName ? request.middleName : ''
                }${request.lastName}`}</td>
                <td>{request.idType}</td>
                <td>{new Date(request.dob).toLocaleDateString()}</td>
                <td>{request.status}</td>
                <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                <td>
                  <Button color="primary" onClick={() => toggleModal(request)}>
                    Review
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalBody>
          <h5>Review KYC Request</h5>
          {selectedRequest && (
            <div>
              <p>
                <strong>Name:</strong>{' '}
                {`${selectedRequest.firstName} ${
                  selectedRequest.middleName
                    ? selectedRequest.middleName + ' '
                    : ''
                }${selectedRequest.lastName}`}
              </p>
              <p>
                <strong>Email:</strong> {selectedRequest.email}
              </p>
              <p>
                <strong>Phone Number:</strong> {selectedRequest.phoneNumber}
              </p>
              <p>
                <strong>Country:</strong> {selectedRequest.resCountry}
              </p>
              <p>
                <strong>ID Type:</strong> {selectedRequest.idType}
              </p>
              <p>
                <strong>ID Number:</strong> {selectedRequest.idNumber}
              </p>
              <p>
                <strong>Issue Date:</strong>{' '}
                {new Date(selectedRequest.issueDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Expiry Date:</strong>{' '}
                {new Date(selectedRequest.expiryDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Address:</strong> {selectedRequest.resAddress}
              </p>
              <p>
                <strong>City:</strong> {selectedRequest.resCity}
              </p>
              <p>
                <strong>State:</strong> {selectedRequest.resState}
              </p>
              <p>
                <strong>Postal Code:</strong> {selectedRequest.resPostalCode}
              </p>
              <p>
                <strong>Selfie Photo:</strong> {selectedRequest.selfiePhotoFile}
              </p>
              <p>
                <strong>ID Photo:</strong> {selectedRequest.idPhotoFile}
              </p>
              <Form>
                <FormGroup>
                  <Label for="rejectionReasons">
                    Rejection Reasons <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="rejectionReasons"
                    id="rejectionReasons"
                    value={rejectionReasons}
                    onChange={handleReasonsChange}
                    required
                  />
                </FormGroup>
              </Form>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleApprove}>
            Approve
          </Button>{' '}
          <Button color="danger" onClick={handleReject}>
            Reject
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default KYCRequests;
