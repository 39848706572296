import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const BuyNowModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Buy Now</ModalHeader>
      <ModalBody>
        <div className="buy-now-links">
          <Button block href="https://latoken.com" target="_blank">
            LATOKEN
          </Button>
          <Button
            block
            href="https://www.dextools.io/app/en/fantom/pair-explorer/0x7a839c5324246a4d45419380ebc58f3fd136b823?t=1708627226281"
            target="_blank"
            className="mt-4"
          >
            DEXTools
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

BuyNowModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default BuyNowModal;
