import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import './dashboard.css';

export default function MainDashboard() {

  return (
    <>
      <div className="main-card">
        <Row>
          <Col className="mb-3" md="4" sm="12">
            <div className="balance-card">
              <Card className="coin-card">
                <CardBody className="p-4 d-flex flex-column justify-content-center">
                  <div className="title mb-4">
                    <h4>Users</h4>
                  </div>
                </CardBody>
                
              </Card>
            </div>
          </Col>
          <Col className="mb-3" md="4" sm="12">
            <div className="balance-card">
              <Card className="coin-card">
                <CardBody className="p-4 d-flex flex-column justify-content-center">
                  <div className="title mb-4">
                    <h4>Pending Orders</h4>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col className="mb-3" md="4" sm="12">
            <div className="balance-card">
              <Card className="coin-card">
                <CardBody className="p-4 d-flex flex-column justify-content-center">
                  <div className="title mb-4">
                    <h4>Pending Withdrawals</h4>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
