import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import bg from '../../assets/images/bg3_new.png';

import './Faq.css';

export default function AssetTokenization() {
  return (
    <>
      <section id="tokenization" className="tokenization-section">
        <Container>
          <div className="section-head text-center">
            <h2 className="section-title">Asset Tokenization</h2>
            <p>
              Revolutionize the way you invest in agriculture, real estate and other real world assets - #RWA.
              Tokenize your assets and unlock a world of opportunities.
            </p>
          </div>
          <Row>
            <Col md="4">
              {/* Content block 1: Secured Transaction */}
              <div className="content-block">
                <h4 className="content-titlet">Secured Transaction</h4>
                <p className="content-description">
                  Enjoy peace of mind with secured transactions through asset
                  tokenization. Your investments are protected through advanced
                  security measures, ensuring a safe and reliable investment
                  experience.
                </p>
              </div>

              {/* Content block 2: Embrace the New Reality of Capital Markets */}
              <div className="content-block">
                <h4 className="content-titlet">
                  Eliminate Complex Settlement through Tokenization
                </h4>
                <p className="content-description">
                  Say goodbye to complex and time-consuming settlement
                  processes. Asset tokenization streamlines the settlement
                  process, making it faster, more efficient, and transparent.
                  Enjoy hassle-free transactions with reduced administrative
                  overheads.
                </p>
              </div>
            </Col>
            <Col md="4" className="text-center d-flex align-items-center">
              <div className="rounded-image">
                <img src={bg} alt="Tokenization" />
              </div>
            </Col>
            <Col md="4">
              {/* Additional Content Block 1 */}
              <div className="content-block ct-right">
                <h4 className="content-titlet">
                  Global Investment Opportunities
                </h4>
                <p className="content-description">
                  Expand your investment horizons globally with asset
                  tokenization. Gain access to a wide range of investment
                  opportunities in various locations, diversifying your
                  portfolio and capitalizing on emerging markets.
                </p>
              </div>

              {/* Additional Content Block 2 */}
              <div className="content-block ct-right">
                <h4 className="content-titlet">Expertise and Support</h4>
                <p className="content-description">
                  Leverage our leading products and expertise in asset
                  tokenization. Our team of experts is dedicated to guiding you
                  through the process, providing personalized support and
                  ensuring your investment journey is a success.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
