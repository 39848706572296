import React from 'react';
import roadmap from '../../assets/images/1.jpg';

export default function Roadmap() {
  const openImage = () => {
    window.open(roadmap, '_blank');
  };

  return (
    <div className="area-bg">
      <section id="roadmap" className="roadmap-area pt-130 pb-130">
        <div className="roadmap-img">
          <img
            src={roadmap}
            alt="roadmap"
            onClick={openImage}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </section>
    </div>
  );
}
